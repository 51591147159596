import axios, { AxiosError, AxiosInstance, AxiosResponse } from "axios";
import { ElLoading, ElMessage, ElMessageBox } from "element-plus";
import storage from "@/utils/storage";
import type { HxRequestInterceptors, HxRequestConfig } from "./type";
import router from "@/router";
import _ from "lodash";
import logger from "@/logger";

const DEFAULT_LOADING = true;
/** 数据请求成功 */
const SUCCESS_CODE = 0;
/** 注册信息审核未通过 */
const REGIST_ERR_CODE = 100;
/** token认证失败 */
const TOKEN_INVALID_CODE = 200;
/** 异地登陆异常 */
const SING_LOGIN_CODE = 201;
/** 前端页面升级，需要刷新浏览器 */
const PC_VERSION_UPDATE_CODE = 300;
/** 数据请求失败 */
const SERVER_ERROR_CODE = 500;
/** 批量上传文件解析失败 */
const EXCEL_PAESE_ERROR_CODE = 666;
/** 产品库存不足，不能出库 */
const STORE_CK_ERROR_CODE = 777;
/** 工单收银错误码 */
const SHORDER_SHOUYIN_ERROR_CODE = 778;
/** 通用错误码 */
const ERROR_CODE = 999;

const TOKEN_INVALID = "token认证失败，请重新登录！";
const PC_VERSION_UPDATE = "页面升级了，请刷新浏览器！";
const SINGLE_LOGIN_ERROR_MSG = "您的账号在其他设备上登录，请重新登录！";
const NETWORK_ERROR = "网络或服务器异常，请稍后重试！";

class HxRequest {
  instance?: AxiosInstance;
  loadingInstance?: any;
  interceptors?: HxRequestInterceptors;
  showLoading: boolean; // 默认显示loading
  isDownload: boolean; // 是否下载文件：默认false

  constructor(config: HxRequestConfig) {
    this.instance = axios.create(config);
    this.showLoading = config.showLoading ?? DEFAULT_LOADING;
    this.isDownload = config.isDownload ?? false;
    this.interceptors = config.interceptors;
    // 解决每次ajax请求，对应的sessionId不一致的问题
    // this.instance.defaults.withCredentials = true;

    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    );
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    );
    // 所有实例都有的拦截器
    this.instance.interceptors.request.use(
      (conf: HxRequestConfig) => {
        const userId = storage.getItem("userId") ?? "";
        const userCode = storage.getItem("userCode") ?? "";
        const token = storage.getItem("token") ?? "";
        if (conf.headers) {
          conf.headers["Access-Control-Allow-Origin"] = "*";
          conf.headers["X-Access-Token"] = token;
          conf.headers["userId"] = userId;
          conf.headers["userCode"] = userCode;
          conf.headers["from"] = "pc";
          conf.headers["chmd-version"] = import.meta.env.VITE_VERSION;
          if (!conf.headers.Authorization) {
            conf.headers.Authorization = token;
          }
        }
        logger.log("request-->", conf);
        return conf;
      },
      (error: any) => {
        logger.error("request error-->", error);
        return error;
      }
    );
    this.instance.interceptors.response.use(
      (res: AxiosResponse) => {
        logger.log("response-->", res);
        if (res instanceof AxiosError) {
          ElMessage.error(NETWORK_ERROR);
          return Promise.reject(NETWORK_ERROR);
        }
        const hxConfig: HxRequestConfig = res.config;
        if (hxConfig.isDownload) {
          return res.data;
        }
        const { code, msg } = res.data;
        if (code === undefined || code === null) {
          const responseURL = res.request.responseURL;
          if (!_.isEmpty(responseURL) && responseURL.indexOf("login") >= 0) {
            // 访问服务器接口，重定向到登陆页面
            this.tokenInvalid();
            return;
          }
        }
        if (code === SUCCESS_CODE) {
          const token = res.headers.token;
          if (!_.isEmpty(token)) {
            storage.setItem("token", token);
          }
          return res.data;
        } else if (
          code === EXCEL_PAESE_ERROR_CODE ||
          code === STORE_CK_ERROR_CODE ||
          code === SHORDER_SHOUYIN_ERROR_CODE
        ) {
          return res.data;
        } else if (code === ERROR_CODE || code === REGIST_ERR_CODE) {
          ElMessageBox.alert(msg || NETWORK_ERROR, "提示", {
            confirmButtonText: "确定",
          });
          return Promise.reject(NETWORK_ERROR);
        } else if (code === SERVER_ERROR_CODE) {
          const responseURL = res.request.responseURL;
          if (!_.isEmpty(responseURL) && responseURL.indexOf("login") >= 0) {
            // 访问服务器接口，重定向到登陆页面
            this.tokenInvalid();
            return;
          }
          ElMessageBox.alert(msg || NETWORK_ERROR, "提示", {
            confirmButtonText: "确定",
          });
          return Promise.reject(NETWORK_ERROR);
        } else if (code === TOKEN_INVALID_CODE) {
          this.tokenInvalid();
          return Promise.reject(TOKEN_INVALID);
        } else if (code === SING_LOGIN_CODE) {
          this.singleLoginError();
          return Promise.reject(SINGLE_LOGIN_ERROR_MSG);
        } else if (code === PC_VERSION_UPDATE_CODE) {
          this.pcUpdate();
          return Promise.reject(PC_VERSION_UPDATE);
        } else {
          ElMessage.error(msg || NETWORK_ERROR);
          return Promise.reject(msg || NETWORK_ERROR);
        }
        return res;
      },
      (error: any) => {
        logger.error("response error-->", error);
        if (this.showLoading) {
          this.loadingInstance?.close();
        }
        return error;
      }
    );
  }
  request<T>(config: HxRequestConfig<T>): Promise<T> {
    return new Promise((resolve, reject) => {
      if (config.interceptors?.requestInterceptor) {
        // 执行单独请求的interceptor
        config = config.interceptors.requestInterceptor(config);
      }
      this.showLoading = config.showLoading ?? DEFAULT_LOADING;
      if (this.showLoading) {
        this.loadingInstance = ElLoading.service({
          lock: true,
          text: "加载中...",
          background: "rgba(0,0,0,0.5)",
        });
      }
      this.instance
        ?.request<any, T>(config)
        .then((res) => {
          this.loadingInstance?.close();
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res);
          }
          return resolve(res);
        })
        .catch((error: any) => {
          this.loadingInstance?.close();
          return reject(error);
        });
    });
  }
  // DEMO：hxRequest.get<DataType>({url:'xxx', params: {}, showLoading:false})
  get<T>(config: HxRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "GET" });
  }
  // DEMO：hxRequest.post<DataType>({url:'xxx', data: {}, showLoading:false})
  post<T>(config: HxRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "POST" });
  }
  delete<T>(config: HxRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "DELETE" });
  }
  patch<T>(config: HxRequestConfig<T>): Promise<T> {
    return this.request<T>({ ...config, method: "PATCH" });
  }
  pcUpdate(): void {
    ElMessageBox.alert(PC_VERSION_UPDATE, "提示", {
      confirmButtonText: "确定",
      callback: () => {
        window.location.reload();
      },
    });
  }
  tokenInvalid(): void {
    ElMessage.error(TOKEN_INVALID);
    storage.clear();
    setTimeout(() => {
      router.push("/login");
    }, 1500);
  }
  singleLoginError(): void {
    ElMessageBox.alert(SINGLE_LOGIN_ERROR_MSG, "提示", {
      confirmButtonText: "确定",
    });
    storage.clear();
    setTimeout(() => {
      router.push("/login");
    }, 1500);
  }
}

export default HxRequest;
