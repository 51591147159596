import { defineStore } from "pinia";
import storage from "@/utils/storage";
import { Ref, ref } from "vue";
import _ from "lodash";
import { UserInfoType } from "@/types";

const useUserInfoStore = defineStore("userInfoStore", () => {
  const userInfo = ref<UserInfoType>({ id: "" });
  // 当前门店
  let shopName = "";
  let shopId = "";

  function setShopName(name: string) {
    shopName = name;
    storage.setItem("shopName", name);
  }
  function getShopName() {
    if (_.isEmpty(shopName)) {
      const str: string = storage.getItem("shopName");
      if (_.isEmpty(str)) {
        shopName = "";
      } else {
        shopName = str;
      }
    }
    return shopName;
  }
  function setShopId(id: string) {
    shopId = id;
    storage.setItem("shopId", id);
  }
  function getShopId() {
    if (_.isEmpty(shopId)) {
      const str: string = storage.getItem("shopId");
      if (_.isEmpty(str)) {
        shopId = "";
      } else {
        shopId = str;
      }
    }
    return shopId;
  }
  function saveUserInfo(newUserInfo: UserInfoType) {
    userInfo.value = newUserInfo;
    storage.setItem("userInfo", JSON.stringify(newUserInfo));
  }
  function getUserInfo(): UserInfoType {
    if (!userInfo.value || _.isEmpty(userInfo.value.id)) {
      const str: string = storage.getItem("userInfo");
      if (_.isEmpty(str)) {
        userInfo.value = { id: "" };
      } else {
        userInfo.value = JSON.parse(str);
      }
    }
    return userInfo.value;
  }
  function getUserId(): string {
    const user = getUserInfo();
    return user.id ?? "";
  }
  function getUserCode(): string {
    const user = getUserInfo();
    return user.code ?? "";
  }
  function getUserType(): string {
    const user = getUserInfo();
    return user.userType ?? "";
  }
  function getDeptType(): string {
    const user = getUserInfo();
    return user.deptType ?? "";
  }
  function getDeptId(): string {
    const user = getUserInfo();
    return user.deptId ?? "";
  }
  function getDeptName(): string {
    const user = getUserInfo();
    return user.deptName ?? "";
  }
  function isKefu(): boolean {
    const user = getUserInfo();
    if (!_.isEmpty(user.id)) {
      if (user.userType === "0" || user.userType === "1") {
        return true;
      }
    }
    return false;
  }
  function isXlc(): boolean {
    const user = getUserInfo();
    if (!_.isEmpty(user.id)) {
      if (user.userType === "2" || user.userType === "3") {
        return true;
      }
    }
    return false;
  }
  return {
    saveUserInfo,
    getUserInfo,
    getUserId,
    getUserCode,
    getUserType,
    getDeptType,
    setShopName,
    setShopId,
    getShopName,
    getShopId,
    getDeptId,
    getDeptName,
    isKefu,
    isXlc,
  };
});
export default useUserInfoStore;
