import _ from "lodash";
import config from "@/config";

export default {
  log(...vars: any[]) {
    if (config.env !== "production") {
      if (vars && vars.length > 0) {
        const len = vars.length;
        if (len === 1) {
          console.log(vars);
        } else if (len === 2) {
          console.log(vars[0], vars[1]);
        } else if (len === 3) {
          console.log(vars[0], vars[1], vars[2]);
        } else if (len === 4) {
          console.log(vars[0], vars[1], vars[2], vars[3]);
        } else if (len === 5) {
          console.log(vars[0], vars[1], vars[2], vars[3], vars[4]);
        } else {
          console.log(vars);
        }
      }
    }
  },
  info(...vars: any[]) {
    if (config.env !== "production") {
      if (vars && vars.length > 0) {
        const len = vars.length;
        if (len === 1) {
          console.info(vars);
        } else if (len === 2) {
          console.info(vars[0], vars[1]);
        } else if (len === 3) {
          console.info(vars[0], vars[1], vars[2]);
        } else if (len === 4) {
          console.info(vars[0], vars[1], vars[2], vars[3]);
        } else if (len === 5) {
          console.info(vars[0], vars[1], vars[2], vars[3], vars[4]);
        } else {
          console.info(vars);
        }
      }
    }
  },
  warn(...vars: any[]) {
    if (config.env !== "production") {
      if (vars && vars.length > 0) {
        const len = vars.length;
        if (len === 1) {
          console.warn(vars);
        } else if (len === 2) {
          console.warn(vars[0], vars[1]);
        } else if (len === 3) {
          console.warn(vars[0], vars[1], vars[2]);
        } else if (len === 4) {
          console.warn(vars[0], vars[1], vars[2], vars[3]);
        } else if (len === 5) {
          console.warn(vars[0], vars[1], vars[2], vars[3], vars[4]);
        } else {
          console.warn(vars);
        }
      }
    }
  },
  error(...vars: any[]) {
    if (config.env !== "production") {
      if (vars && vars.length > 0) {
        const len = vars.length;
        if (len === 1) {
          console.error(vars);
        } else if (len === 2) {
          console.error(vars[0], vars[1]);
        } else if (len === 3) {
          console.error(vars[0], vars[1], vars[2]);
        } else if (len === 4) {
          console.error(vars[0], vars[1], vars[2], vars[3]);
        } else if (len === 5) {
          console.error(vars[0], vars[1], vars[2], vars[3], vars[4]);
        } else {
          console.error(vars);
        }
      }
    }
  },
};
