import { createApp, ref } from "vue";
import "tailwindcss/tailwind.css";
import ElementPlus from "element-plus";
import zhCn from "element-plus/es/locale/lang/zh-cn";
import "animate.css";
import "normalize.css";
import "element-plus/dist/index.css";
import "element-plus/theme-chalk/display.css";
import print from "vue3-print-nb"; // 打印插件
import VueQrcode from "@chenfengyuan/vue-qrcode"; // QRcode 二维码插件
import request from "@/service";
import logger from "@/logger";
import storage from "./utils/storage";
import util from "./utils/util";
import App from "./App.vue";
import router from "./router";
import { createPinia } from "pinia";
import "default-passive-events";
import _ from "lodash";
import qs from "qs";
import { useUserInfoStore, useMenuStore } from "@/stores";
import "./rem";

// 如果您正在使用CDN引入，请删除下面一行。
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

console.log("环境变量-->", import.meta.env);
const submitChmdVersion = async () => {
  try {
    const res: any = await request.post({
      url: "/version/setChmdVersion",
      data: {},
      showLoading: false,
    });
  } catch (err) {
    logger.error(err);
  }
};
submitChmdVersion();
const app = createApp(App);
//设置全局变量
app.config.globalProperties.$logger = logger;
app.config.globalProperties.$request = request;
app.config.globalProperties.$storage = storage;
app.config.globalProperties.$qs = qs;
app.config.globalProperties.$util = util;

app.use(ElementPlus, {
  locale: zhCn,
  // size: "small",
});
app.use(createPinia());
app.use(print); // 打印插件-全局配置
app.component(VueQrcode.name, VueQrcode); // 二维码插件
app.config.errorHandler = (err: any, instance: any, info: any) => {
  // 向追踪服务报告错误
  logger.log("errorHandler-->", err, instance, info);
};
// 全局使用ICON
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
/**
 * 导航守卫
 * @param to Route对象，即将跳转到的Route对象
 * @parma from Route对象，从哪一个路由对象导航过来的
 * @param next 第三个参数，在vue-router里面不推荐使用了
 * @return 1，false 不进行导航；
 *         2，true、undefined或不写返回值 进行默认导航；
 *         3，字符串：路径，跳转到对应的路径中
 *         4，对象，里面包含path、query、params等信息
 */
const registerRouteFresh = ref(true); // 定义标识，记录路由是否添加
const menuStore = useMenuStore();
const userInfoStore = useUserInfoStore();
const modules: Record<string, () => Promise<unknown>> = import.meta.glob(
  "/src/views/**/*.vue"
);
router.beforeEach((to: { path: string | string[] }, from: any) => {
  const userId = userInfoStore.getUserId();
  logger.log("beforeEach-->to===", to, "，from===", from);
  if (to.path === "/login") {
    return true;
  } else if (_.isEmpty(userId)) {
    return router.replace("/login");
  } else if (to.path === "/index") {
    return router.replace("/home");
  } else if (to.path === "/home") {
    return true;
  } else {
    if (
      registerRouteFresh.value &&
      menuStore.getMenuLength() > 0 &&
      menuStore.getRouterLength() <= 0
    ) {
      menuStore.getMenuList().forEach((e: any) => {
        const component = modules["/src/views/" + e.componentName + ".vue"];
        router.addRoute("index", {
          path: e.path,
          name: e.name,
          component,
        });
      });
      menuStore.updateRouter();
      return router.replace(`${to.path}`);
    } else {
      router.addRoute(router.options.routes[2]);
    }
  }
  registerRouteFresh.value = false;
  return true;
});
app.use(router);
app.mount("#app");
