import HxRequest from "./request";
import { BASE_URL, TIME_OUT } from "./request/config";

const hxRequest = new HxRequest({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor(config) {
      return config;
    },
    requestInterceptorCatch(error) {
      return error;
    },
    responseInterceptor(res) {
      return res;
    },
    responseInterceptorCatch(error) {
      return error;
    },
  },
});

export default hxRequest;
