import { defineStore } from "pinia";
import storage from "@/utils/storage";
import { ref } from "vue";
import _ from "lodash";
import { MenuType } from "@/types";

const Index = defineStore("menuStore", () => {
  const menuList = ref<MenuType[]>([]);
  const routerLength = ref(0);

  function saveMenuList(list: MenuType[]) {
    menuList.value = list;
    storage.setItem("menuList", JSON.stringify(list));
  }
  function clearAll() {
    menuList.value = [];
    routerLength.value = 0;
    storage.setItem("menuList", "");
  }
  function addItem(routerRecord: any) {
    storage.setItem("menuList", "");
    getMenuList().push(routerRecord);
    saveMenuList(getMenuList());
  }
  function getMenuList() {
    if (!menuList.value || menuList.value.length <= 0) {
      const str: string = storage.getItem("menuList");
      if (_.isEmpty(str)) {
        menuList.value = [];
      } else {
        menuList.value = JSON.parse(str);
      }
    }
    return menuList.value;
  }
  function getMenuLength() {
    return getMenuList().length;
  }
  function clearRouter() {
    routerLength.value = 0;
  }
  function getRouterLength() {
    return routerLength.value;
  }
  function updateRouter() {
    routerLength.value = getMenuLength();
  }
  return {
    addItem,
    saveMenuList,
    getMenuList,
    getMenuLength,
    clearRouter,
    getRouterLength,
    updateRouter,
    clearAll,
  };
});
export default Index;
