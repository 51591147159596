import _ from "lodash";

export default {
  uuid(len: number, radix: number) {
    const chars =
      "0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz".split(
        ""
      );
    const uuid: any[] = [];
    let i = 0;
    radix = radix || chars.length;

    if (len) {
      // Compact form
      for (i = 0; i < len; i++) {
        uuid[i] = chars[0 | (Math.random() * radix)];
      }
    } else {
      // rfc4122, version 4 form
      let r;

      // rfc4122 requires these characters
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = "-";
      uuid[14] = "4";

      // Fill in random data. At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | (Math.random() * 16);
          uuid[i] = chars[i == 19 ? (r & 0x3) | 0x8 : r];
        }
      }
    }
    const res = uuid.join("").replaceAll("-", "");
    return res;
  },

  isEmpty(message: string) {
    if (message == "" || message == undefined || message == null) {
      return true;
    }
    return false;
  },

  doEmpty(message: string, dfVal?: string | undefined) {
    let isEmpty = false;
    if (message == "" || message == undefined || message == null) {
      isEmpty = true;
    }
    if (isEmpty) {
      let isDefEmpty = false;
      if (dfVal == "" || dfVal == undefined || dfVal == null) {
        isDefEmpty = true;
      }
      if (!isDefEmpty) {
        return dfVal;
      }
      return "";
    }
    return message;
  },

  numberCheck(val: string, len: number) {
    if (!val) {
      return;
    }
    const b = parseFloat(val);
    let res = Number(b).toFixed(len);
    const dot = res.indexOf(".");
    if (dot < 0) {
      return res;
    }
    const sub = res.substring(dot, res.length);
    if (sub.endsWith("00")) {
      res = res.substring(0, dot);
    } else if (sub.endsWith("0")) {
      res = res.substring(0, dot + 2);
    }
    return parseFloat(res);
  },
  copyProperties(target: any, source: any) {
    if (_.isObject(target) && _.isObject(source)) {
      const tarKeys = _.keys(target);
      if (_.isArray(tarKeys) && tarKeys.length > 0) {
        tarKeys.forEach((tarKey) => {
          if (_.hasIn(source, tarKey)) {
            if (isValidKey(tarKey, source)) {
              target[tarKey] = source[tarKey];
            }
          }
        });
      }
    }
    return target;
  },
  digitUppercase(n: number) {
    const fraction = ["角", "分"];
    const digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    const unit = [
      ["元", "万", "亿"],
      ["", "拾", "佰", "仟"],
    ];
    const head = n < 0 ? "欠" : "";
    n = Math.abs(n);
    let s = "";
    for (let i = 0; i < fraction.length; i++) {
      s += (
        digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
      ).replace(/零./, "");
    }
    s = s || "整";
    n = Math.floor(n);
    for (let i = 0; i < unit[0].length && n > 0; i++) {
      let p = "";
      for (let j = 0; j < unit[1].length && n > 0; j++) {
        p = digit[n % 10] + unit[1][j] + p;
        n = Math.floor(n / 10);
      }
      s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
    }
    return (
      head +
      s
        .replace(/(零.)*零元/, "元")
        .replace(/(零.)+/g, "零")
        .replace(/^整$/, "零元整")
    );
  },
  dateToString(date: Date) {
    const year = date.getFullYear();
    let month = (date.getMonth() + 1).toString();
    let day = date.getDate().toString();
    if (month.length === 1) {
      month = "0" + month;
    }
    if (day.length === 1) {
      day = "0" + day;
    }
    return year + "-" + month + "-" + day;
  },
  getUnitName(unit: string) {
    // 1个 2只 3件 4套 5箱 6副 7面
    if (!unit || _.isEmpty(unit)) {
      return "";
    }
    if (unit === "1") {
      return "个";
    }
    if (unit === "2") {
      return "只";
    }
    if (unit === "3") {
      return "件";
    }
    if (unit === "4") {
      return "套";
    }
    if (unit === "5") {
      return "箱";
    }
    if (unit === "6") {
      return "副";
    }
    if (unit === "7") {
      return "面";
    }
    if (unit === "8") {
      return "桶";
    }
    if (unit === "9") {
      return "根";
    }
    if (unit === "10") {
      return "瓶";
    }
    if (unit === "11") {
      return "条";
    }
    if (unit === "12") {
      return "罐";
    }
    if (unit === "13") {
      return "对";
    }
    if (unit === "14") {
      return "升";
    }
    return "";
  },
  getQuanTypeName(val: string) {
    if (!val || _.isEmpty(val)) {
      return "";
    }
    if (val === "1") {
      return "洗车卡";
    }
    if (val === "2") {
      return "小保养";
    }
    if (val === "3") {
      return "大保养";
    }
    if (val === "4") {
      return "买菜";
    }
    if (val === "5") {
      return "美容卡";
    }
    if (val === "6") {
      return "会员卡";
    }
    return "-";
  },
  getPzName(pz: string) {
    if (!pz || _.isEmpty(pz)) {
      return "";
    }
    if (pz === "1") {
      return "原厂件";
    }
    if (pz === "2") {
      return "品牌件";
    }
    if (pz === "3") {
      return "副厂件";
    }
    if (pz === "4") {
      return "拆车件";
    }
    return "-";
  },
  /**
   * 获取：客户来源：1微信 2朋友介绍 3客户介绍 4户外广告 5路过 6其他平台 7保险公司 8抖音 9快手 10美团
   */
  getLaiYuanName(laiYuan: string) {
    if ("1" === laiYuan) {
      return "微信";
    }
    if ("2" === laiYuan) {
      return "朋友介绍";
    }
    if ("3" === laiYuan) {
      return "客户介绍";
    }
    if ("4" === laiYuan) {
      return "户外广告";
    }
    if ("5" === laiYuan) {
      return "路过";
    }
    if ("6" === laiYuan) {
      return "其他平台";
    }
    if ("7" === laiYuan) {
      return "保险公司";
    }
    if ("8" === laiYuan) {
      return "抖音";
    }
    if ("9" === laiYuan) {
      return "快手";
    }
    if ("10" === laiYuan) {
      return "美团";
    }
    return "";
  },
  // 1提现 2充值卡 3余额支付普通订单 4余额支付工单 5客户使用次卡
  getTxTypeName(value: string) {
    if (value === "1") {
      return "提现";
    }
    if (value === "2") {
      return "充值卡";
    }
    if (value === "3") {
      return "支付订单";
    }
    if (value === "4") {
      return "支付工单";
    }
    if (value === "5") {
      return "核销平台券";
    }
    if (value === "6") {
      return "会员卡充值余额";
    }
    if (value === "7") {
      return "余额付款";
    }
    return "-";
  },
  // 1洗车 2小保养 3大保养 4卖菜 5配件订单 6门店工单 7销售充值卡
  getJsTypeName(value: string) {
    if (value === "1") {
      return "洗车卡";
    }
    if (value === "2") {
      return "小保养";
    }
    if (value === "3") {
      return "大保养";
    }
    if (value === "4") {
      return "卖菜";
    }
    if (value === "5") {
      return "美容卡";
    }
    if (value === "6") {
      return "工单";
    }
    if (value === "7") {
      return "充值卡";
    }
    if (value === "8") {
      return "会员卡";
    }
    if (value === "10") {
      return "商城订单";
    }
    if (value === "11") {
      return "平台分佣";
    }
    if (value === "12") {
      return "套餐订单结算";
    }
    return "-";
  },
};
export function isValidKey(
  key: string | number | symbol,
  object: object
): key is keyof typeof object {
  return key in object;
}
