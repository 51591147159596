let BASE_URL = "";
const TIME_OUT = 60 * 3 * 1000;
const mock = false;
// 车辆档案会用到HOST
let HOST = "";

if (import.meta.env.MODE === "development") {
  BASE_URL = "/api";
  HOST = "http://192.168.2.60:8182";
  if (mock) {
    BASE_URL = "/mockapi";
  }
} else if (import.meta.env.MODE === "production") {
  BASE_URL = "/chmd";
  HOST = "/chmd";
}

export { BASE_URL, TIME_OUT, HOST };
