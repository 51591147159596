import {
  createRouter,
  createWebHistory,
  createWebHashHistory,
} from "vue-router"; // createWebHashHistory
import Index from "@/components/Index.vue";

const routes = [
  {
    name: "index",
    path: "/",
    meta: {
      title: "首页",
    },
    component: Index,
    redirect: "/home",
    children: [
      {
        name: "home",
        path: "/home",
        meta: {
          title: "首页",
        },
        component: () => import("@/views/Home.vue"), // 路由懒加载
      },
    ],
  },
  {
    name: "login",
    path: "/login",
    meta: {
      title: "登录",
    },
    component: () => import("@/views/user/Login.vue"),
  },
  {
    name: "notFound",
    path: "/:patchMatch(.*)*",
    meta: {
      title: "页面不存在",
    },
    component: () => import("@/views/notice/NotFound.vue"),
  },
];
const router = createRouter({
  // URL hash：createWebHashHistory，还有HTML5的History：createWebHistory()
  history: createWebHashHistory(),
  routes,
});
export default router;
