<script lang="ts">
import { onBeforeMount, onMounted } from "vue";

export default {
  name: "app",
  setup() {
    onBeforeMount(() => {});
    onMounted(() => {});
  },
};
</script>

<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<style>
@import "@/assets/style/mystyle.css";
</style>
